import React, { useState, useRef } from "react"
import Layout from "../components/layout"
import Pozicije from "../components/tables/Pozicije"
import "aos/dist/aos.css"
import AOS from "aos"
import { useEffect } from "react"
import axios from "axios"
import Swal from "sweetalert2"
import "sweetalert2/src/sweetalert2.scss"
import Head from "../components/head"

const Karijera = () => {
  const [fileName, setFileName] = useState("")
  function handleChange(event) {
    console.log(`Selected file - ${event.target.files[0].name}`)
    setFileName(`${event.target.files[0].name}`)
  }

  useEffect(() => {
    AOS.init({
      once: true,
      easing: "ease-in-out",
    })
  }, [])
  const handleSubmit = e => {
    e.preventDefault()
    const data = e.target
    var formData = new FormData(data)
    // formData.set("ime", "ime")
    // formData.set("pozicija", "ime")
    // formData.set("grad", "ime")
    // formData.set("datum", "ime")
    // formData.set("ime", "ime")

    axios
      .post("https://dakcomerc.herokuapp.com/send", formData)
      .then(r => {
        console.log(r)
      })
      .catch(r => {
        console.log(r)
      })
    data.reset()
    setFileName("")
    Swal.fire({
      title: "Hvala!",
      text: "Uspešno ste poslali prijavu.",
      icon: "success",
      timer: 1000,
    })
  }

  return (
    <Layout>
      <Head title="Karijera" />
      <section
        className="hero karijera-hero is-fullheight-with-navbar has-background-link"
        data-aos="slide-down"
        data-aos-duration="1000"
      >
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="columns is-multiline is-flex is-fullwidth karijera">
              <div className="column is-full-mobile is-two-fifths-desktop has-text-left has-text-white karijera-column">
                <h1 className="title is-1 is-size-3-mobile has-text-white">
                  Karijera
                </h1>
                <p
                  className="is-size-4 is-size-6-mobile"
                  style={{ paddingBottom: "15px" }}
                >
                  Trenutno aktuelne pozicije u Dak Comerc-u:
                </p>
                <Pozicije />
              </div>
              <div className="column is-full-mobile is-two-fifths-desktop  has-text-left box karijera-column-2">
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label">Ime i Prezime</label>
                    <div className="control">
                      <input
                        required
                        className="input is-small"
                        type="text"
                        name="ime"
                      />
                    </div>
                  </div>
                  <div
                    className="is-flex-desktop"
                    style={{ justifyContent: "space-around" }}
                  >
                    <div className="field">
                      <label className="label">Pozicija</label>
                      <div className="control">
                        <div className="select">
                          <select name="pozicija" required>
                            <option>Komercijalista</option>
                            <option>Trgovac</option>
                            <option>Magacioner</option>
                            <option>Poslovođa</option>
                            <option>Fizički radnik</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Grad</label>
                      <div className="control">
                        <div className="select">
                          <select name="grad" required>
                            <option>Novi Sad</option>
                            <option>Gornji Milanovac</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Datum rođenja</label>
                    <div className="control">
                      <input
                        required
                        className="input is-small"
                        type="date"
                        name="datum"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Telefon</label>
                    <div className="control">
                      <input
                        required
                        className="input is-small"
                        type="tel"
                        name="telefon"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                      <input
                        required
                        className="input is-small"
                        type="email"
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <div className="file is-link">
                        <label className="file-label">
                          <input
                            required
                            className="file-input"
                            id="fileInput"
                            type="file"
                            onChange={handleChange}
                            name="cv"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">Dodajte CV</span>
                          </span>
                          {fileName == "" ? (
                            <span></span>
                          ) : (
                            <span className="file-name">{fileName}</span>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="control">
                    <button
                      type="submit"
                      className="button is-link is-fullwidth has-text-weight-medium is-normal"
                    >
                      Pošalji
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Karijera
