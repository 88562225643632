import React from "react"

const Pozicije = () => {
  return (
    <table className="table pozicije-table is-bordered is-hoverable">
      <tbody>
        <tr className="has-background-link">
          <th className="has-text-white has-text-centered">Pozicija</th>
          <th className="has-text-white has-text-centered">Grad</th>
        </tr>
        <tr>
          <td>Komercijalista</td>
          <td>Novi Sad / Gornji Milanovac</td>
        </tr>
        <tr>
          <td>Trgovac</td>
          <td>Novi Sad / Gornji Milanovac</td>
        </tr>
        <tr>
          <td>Magacioner</td>
          <td>Novi Sad / Gornji Milanovac</td>
        </tr>
        <tr>
          <td>Poslovođa</td>
          <td>Novi Sad / Gornji Milanovac</td>
        </tr>
        <tr>
          <td>Fizički radanik</td>
          <td>Novi Sad / Gornji Milanovac</td>
        </tr>
      </tbody>
    </table>
  )
}

export default Pozicije
